import React, { useState, useEffect } from "react";
import { createHash } from "../../actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
  const [userName, setUserName] = useState("");
  const [passwordResetHash, setPasswordResetHash] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState("");

  const isPasswordReset = useSelector((state) => state.auth.isPasswordReset);
  const error = useSelector((state) => state.auth.error);
  const resetHash = useQuery().get("h");
  useEffect(() => {
    if (resetHash) {
      setLoading(true);
      axios
        .post("/api/users/find", { passwordReset: resetHash })
        .then((res) => {
          console.log(res);
          if (res.data.user) {
            console.log(res.data.user);
            //password reset hash is not returned when finding a user, but finding a user confirms it is a correct one so set it to state.
            setPasswordResetHash(resetHash);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [resetHash]);

  const submitReset = () => {
    if (password && confirmPassword && password === confirmPassword) {
      if (password.length >= 7) {
        axios
          .post("/api/users/resetPassword", {
            passwordReset: passwordResetHash,
            password: password,
          })
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              alert("Password reset!");
              props.history.push("/login");
            } else {
              setResetError(res.data.error);
            }
          })
          .catch((err) => {
            setResetError(err);
          });
      } else {
        setResetError("Password must be at least 7 characters.");
      }
    } else {
      setResetError(
        "You must fill in both password fields and they must match."
      );
    }
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : passwordResetHash ? (
        <div id="LoginBox">
          <span>{resetError || "Resetting password."}</span>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              submitReset();
            }}
          >
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              id="password"
              type="password"
              placeholder="New Password"
            />

            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              id="confirmpassword"
              type="password"
              placeholder="Confirm Password"
            />
            <input
              type="submit"
              name="btnResetPassword"
              value="Reset Password"
              id="btnResetPassword"
              className="LoginButton"
            />
          </form>
        </div>
      ) : (
        <div id="LoginBox">
          <span>
            {error || isPasswordReset
              ? "Check your email for the password reset link."
              : "Reset password"}
          </span>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(createHash(userName));
            }}
          >
            <input
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              id="username"
              type="text"
              placeholder="Username"
            />

            <input
              type="submit"
              name="btnSendResetEmail"
              value="Send Password Reset Email"
              id="btnSendResetEmail"
              className="LoginButton"
            />
          </form>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
