import React from "react";
import { Field } from "./Field.js";

export function Row(props) {
  let row = props.row;

  return (
    <div className="row">
      {row.map((field, fieldIndex) => {
        let fieldId = `step-${props.stepIndex}-row-${props.rowIndex}-field-${fieldIndex}`;
        return (
          <React.Fragment key={fieldIndex}>
            <Field
              field={field}
              fieldId={fieldId}
              value={field.value || ""} //pass empty string if needed so field is always controlled
              handleChange={props.handleChange}
              handleCheckboxChange={props.handleCheckboxChange}
              handleDatePickerChange={props.handleDatePickerChange}
              breakAfter={row.count}
              onBlur={props.onBlur}
              updateSteps={props.updateSteps}
              disabled={props.disabled}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
