import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../actions/authActions";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const auth = useSelector((state) => state.auth);
  const portal = useSelector((state) => state.selection.portal);
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  //console.log(portal);

  return (
    <div id="Header">
      {auth.isAuthenticated && (auth.user.url || auth.user.isSuperUser) ? (
        <Link
          to={
            auth.user.isSuperUser
              ? `/loanapplication/${portal || 0}`
              : auth.user.url
          }
        >
          Home
        </Link>
      ) : null}
      {auth.isAuthenticated ? <Link to="/admin">Admin</Link> : null}
      {auth.isAuthenticated ? (
        <>
          <Link to="/settings">Settings</Link>
          <a
            href="#"
            onClick={() => {
              dispatch(logoutUser());
              logout({ returnTo: `${window.location.origin}/login` });
            }}
          >
            Log out
          </a>
        </>
      ) : (
        <Link to="/login">Log in</Link>
      )}
    </div>
  );
}

export default Header;
