import React from "react";
import axios from "axios";
import "./App.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LoanApplication } from "./Components/LoanApplication";
import { ViewApplication } from "./Components/ViewApplication";
import Login from "./Components/auth/Login";
import CustomCSS from "./Components/CustomCSS";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import ResetPassword from "./Components/auth/ResetPassword";
import Admin from "./Components/auth/Admin";
import Settings from "./Components/Settings";

/*
TO COPY databases to local
On the server:
cd desktop\mongodb\bin
mongodump --db Acadian_LoanApplication --out /var/backups/mongobackups
mongodump --db Community_loanapplication --out /var/backups/mongobackups
mongodump --db Horizon_loanapplication --out /var/backups/mongobackups
mongodump --db Lahave_loanapplication --out /var/backups/mongobackups
mongodump --db loanapplication_global --out /var/backups/mongobackups
mongodump --db LVCU_loanapplication --out /var/backups/mongobackups
mongodump --db SCCU_loanapplication --out /var/backups/mongobackups
mongodump --db Strathclair_loanapplication --out /var/backups/mongobackups
mongodump --db SVCU_loanapplication --out /var/backups/mongobackups
mongodump --db TheCreditU_loanapplication --out /var/backups/mongobackups
mongodump --db tpcu_loanapplication --out /var/backups/mongobackups
mongodump --db Vanguard_loanapplication --out /var/backups/mongobackups
mongodump --db Victory_loanapplication --out /var/backups/mongobackups

Copy these to local var folder

On local: 
cd C:\Program Files\MongoDB\Server\4.4\bin
mongorestore --db Acadian_LoanApplication --drop "C:/var/Acadian_LoanApplication"
mongorestore --db Community_loanapplication --drop "C:/var/Community_loanapplication"
mongorestore --db Horizon_loanapplication --drop "C:/var/Horizon_loanapplication"
mongorestore --db Lahave_loanapplication --drop "C:/var/Lahave_loanapplication"
mongorestore --db loanapplication_global --drop "C:/var/loanapplication_global"
mongorestore --db LVCU_loanapplication --drop "C:/var/LVCU_loanapplication"
mongorestore --db SCCU_loanapplication --drop "C:/var/SCCU_loanapplication"
mongorestore --db Strathclair_loanapplication --drop "C:/var/Strathclair_loanapplication"
mongorestore --db SVCU_loanapplication --drop "C:/var/SVCU_loanapplication"
mongorestore --db TheCreditU_loanapplication --drop "C:/var/TheCreditU_loanapplication"
mongorestore --db tpcu_loanapplication --drop "C:/var/tpcu_loanapplication"
mongorestore --db Vanguard_loanapplication --drop "C:/var/Vanguard_loanapplication"
mongorestore --db Victory_loanapplication --drop "C:/var/Victory_loanapplication"

cd to project folder
mongo mongoscript.js

copy to atlas
mongodump --db loan-application --archive | mongorestore --drop --uri "mongodb+srv://dbUser:rT9qUue6Fo8bNQoa@cluster0.n08sy.mongodb.net/loan-application?retryWrites=true&w=majority" --archive --ssl --nsExclude "admin.system.*"
*/

function App() {
  // Check for token to keep user logged in
  if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated

    //don't treat the user as logged in if the token is for updating a specific application
    if (!decoded.application_id) {
      store.dispatch(setCurrentUser(decoded)); // Check for expired token
      const currentTime = Date.now() / 1000; // to get in milliseconds
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser()); // Redirect to login
        window.location.href = "./login";
      }
    }
  }

  const PORT = 5050;
  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host;
  baseUrl = baseUrl.replace(/[0-9]{4}/g, PORT);
  axios.defaults.baseURL = baseUrl;

  return (
    <Provider store={store}>
      <CustomCSS />
      <div className="App">
        <Router>
          <Route path="/loanapplication" component={LoanApplication} />
          <Route path="/viewapplication" component={ViewApplication} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/settings" component={Settings} />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
