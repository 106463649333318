import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSelectedPortal } from "../actions/selectionActions";

import { Row } from "./Row.js";
import Validation from "../includes/Validation.js";
//import { getDefaultNormalizer } from "@testing-library/react";
import axios from "axios";

import Cookies from "js-cookie";
import setAuthToken from "../utils/setAuthToken";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Sidebar.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

//fieldName should be unique. To use multiple fields with the same label, use fieldDisplayName, but fieldName will be used if it's not set.
export const addCoordinates = (steps) => {
  //sets up coordinates so a field can easily be found in a copy of the array.
  //NOTE: the x, y, z in the database apparently aren't used currently, and AFAIK were from before this function was implemented.
  for (let x = 0; x < steps.length; x++) {
    let rows = steps[x].rows;
    for (let y = 0; y < rows.length; y++) {
      let row = rows[y];
      for (let z = 0; z < row.length; z++) {
        let field = row[z];
        field.x = x;
        field.y = y;
        field.z = z;
      }
    }
  }
  return steps;
};

export function LoanApplication(props) {
  const dispatch = useDispatch();
  var getUrl = window.location;
  var baseUrl =
    getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1];
  //axios.defaults.baseURL = baseUrl;
  //allows you to skip validation on the steps included in the array to get to the step you need to test quicker.

  //this should be an empty array when not testing
  const debugSkipSteps = [];
  const disableValidation = false;

  let steps = null;
  const [stepsState, setStepsState] = useState(null);
  const [portalId, setPortalId] = useState(null);
  const [formDisplayName, setFormDisplayName] = useState("");
  const [logo, setLogo] = useState(null);
  const [loadingState, setLoadingState] = useState("Loading...");
  const [formID, setFormID] = useState(null);
  const [displayConditions, setDisplayConditions] = useState([]);

  const [demo, setDemo] = useState(false);

  const [enableAutosave, setEnableAutosave] = useState(false);
  //set to true when the user modifies a field, so we can start autosaving only after input
  const [userModified, setUserModified] = useState(false);

  useEffect(() => {
    if (stepsState === null) getForm(steps);
  }, []);

  const addValues = (steps, application) => {
    for (let x = 0; x < steps.length; x++) {
      let rows = steps[x].rows;
      for (let y = 0; y < rows.length; y++) {
        let row = rows[y];
        for (let z = 0; z < row.length; z++) {
          let field = row[z];
          if (application[x][y]) {
            field.value = application[x][y][z].value;
            field.visible = application[x][y][z].visible;
          }
        }
      }
    }
    return steps;
  };

  const getForm = (steps) => {
    axios
      .post("/api/forms/", { url: getUrl.href })
      .then((res) => {
        const form = res.data.forms[0];
        if (form) {
          setEnableAutosave(form.autosave);
          setDisplayConditions(form.displayConditions);
          setFormID(form._id);
          setPortalId(res.data.portalId);
          setFormDisplayName(res.data.displayName);
          setLogo(res.data.logo);
          console.log(logo);

          dispatch(setSelectedPortal(form.portalId));

          if (form.demo) setDemo(true);

          const application_id = Cookies.get("application_id");
          if (application_id) {
            axios
              .post("/api/applications/findOne", { _id: application_id })
              .then((res) => {
                if (!res.data.error) {
                  const application = res.data;
                  steps = addValues(form.steps, JSON.parse(application.values));
                  //steps = addCoordinates(form.steps);
                  //checkDisplayConditions(steps);
                  setCurrentStep(application.step || 0);

                  setStepsState(steps);
                } else {
                  steps = addCoordinates(form.steps);
                  setStepsState(steps);
                }
              })
              .catch((e) => {
                console.log("Failed to get in-progress application.");
                console.log(e);
                Cookies.remove("application_id", { path: "" });
                steps = addCoordinates(form.steps);
                setStepsState(steps);
              });
          } else {
            steps = addCoordinates(form.steps);
            setStepsState(steps);
          }
        } else {
          //we could make it so if no form is found for this domain, create and save a copy of the default form
          //but it's probably easier to just duplicate a form in MongoDB Compass when creating a new instance
          setLoadingState("Form not found for this domain.");
        }
      })
      .catch(() => setLoadingState("Form not found for this domain."));
  };

  const [currentStep, setCurrentStep] = useState(0);

  const [error, setError] = useState([]);

  const handleChange = (e) => {
    updateSteps(e.target.id, e.target.value);
  };

  const handleCheckboxChange = (e) => {
    updateSteps(e.target.id, e.target.checked);
  };

  const removeValidationMessages = (validationMessages, field) => {
    return validationMessages.filter(function (value, index, arr) {
      if (value.matching !== undefined) {
        return field.matching !== value.matching; //false removes the element so if this error message is about a different matching group it is kept
      }
      return field.fieldName !== value.fieldName; //false removes the element so if this error message is about a different field it is kept
    });
  };

  const handleValidateField = (e) => {
    if (disableValidation) return;
    //validates a single field (used in onBlur)
    let arr = e.target.id.split("-");
    let step = arr[1];
    let row = arr[3];
    let fieldC = arr[5];
    let invalid = false;
    let field = stepsState[step].rows[row][fieldC];
    let validationMessage = Validation.validateField(
      field,
      [],
      stepsState,
      setStepsState
    );

    if (validationMessage[0]) invalid = true;

    let validationMessages = [...error];

    //TODO could/should the rest of this function be moved into validateField itself?
    //remove validation messages for this field
    //first, filter out messages that have the same field name.
    validationMessages = removeValidationMessages(validationMessages, field);

    //then add the new error, if there is one
    for (let x in validationMessage) {
      if (
        validationMessage[x] &&
        validationMessage[x].message &&
        !validationMessages.includes(validationMessage[x].message)
      ) {
        validationMessages.push(validationMessage[x]);
      }

      //if this is in a matching group, we also need to validate/invalidate the other(s) in the group
      //but only do that if we're removing the error message or the error message is about matching
      if (
        field.matching !== undefined &&
        (!validationMessage[x] ||
          !validationMessage[x].message ||
          validationMessage[x].message.includes("match"))
      ) {
        setInvalidForMatchingGroup(field.matching, invalid);
      }
    }

    //loop through fields to check if their display condition changed and remove those errors if they should no longer be displayed
    let newSteps = [...stepsState];
    checkDisplayConditions(newSteps, field, validationMessages);

    setError(validationMessages);
    setStepsState(newSteps);
  };

  const checkDisplayConditions = (steps, field, validationMessages) => {
    for (let row of steps[currentStep].rows) {
      for (let loopField of row) {
        if (loopField.conditionalDisplay) {
          let displayCondition = displayConditions[loopField.condition];
          if (displayCondition.fieldName === field.fieldName) {
            //if the field that was edited is the one in the condition
            if (checkFieldMeetsCondition(field, displayCondition)) {
              loopField.visible = true;
            } else {
              loopField.visible = false;
              loopField.invalid = false;
              validationMessages = removeValidationMessages(
                validationMessages,
                loopField
              );
            }
          }
        }
      }
    }
  };

  const handleDatePickerChange = (id, date) => {
    updateSteps(id, date);
  };

  const updateSteps = (id, value) => {
    //make a copy of the array and change the 1 item that changed.
    let newSteps = [...stepsState];

    //use the numbers in the id to locate the item
    let arr = id.split("-");
    let stepCoord = arr[1];
    let rowCoord = arr[3];
    let fieldCoord = arr[5];
    let field = newSteps[stepCoord].rows[rowCoord][fieldCoord];
    //all number fields should always contain numbers
    if (field.fieldType === "number") {
      let newValue = parseInt(value, 10);
      if (Number.isInteger(newValue)) value = newValue;
      else value = field.value; //keep existing value if new value is not an integer
      value = value.toString();
    }
    field.value = value;

    setStepsState(newSteps);
    setUserModified(true);
  };

  const updateStepsWholeField = (id, newField) => {
    //make a copy of the array and change the 1 item that changed.
    let newSteps = [...stepsState];

    //use the numbers in the id to locate the item
    let arr = id.split("-");
    let stepCoord = arr[1];
    let rowCoord = arr[3];
    let fieldCoord = arr[5];
    newSteps[stepCoord].rows[rowCoord][fieldCoord] = newField;

    setStepsState(newSteps);
    setUserModified(true);
  };

  const checkFieldMeetsCondition = (field, condition) => {
    switch (condition.comparison) {
      case "equal":
        return field.value === condition.value;
      case "not":
        return field.value !== condition.value;
      default:
        return false;
    }
  };

  /*
  const findMatchingField = (field, array) => {
    for (let step in array) {
      for (let row in array[step].rows) {
        for (let arF in array[step].rows[row]) {
          let arrayField = array[step].rows[row][arF];
          if (field.fieldName === arrayField.fieldName) {
            return arrayField;
          }
        }
      }
    }
  };
  const getFieldId = (x, y, z) => {
    return "step-" + x + "-row-" + y + "-field-" + z;
  };*/

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const validateStep = (currentStep) => {
    if (disableValidation) return true;
    let validationMessages = [];
    let validatedMatchingGroups = [];

    for (let row of stepsState[currentStep].rows) {
      for (let field of row) {
        let validationMessage = Validation.validateField(
          field,
          validatedMatchingGroups,
          stepsState,
          setStepsState
        );
        for (let x in validationMessage) {
          if (validationMessage[x] && validationMessage[x].message) {
            validationMessages.push(validationMessage[x]);
          }
        }
      }
    }
    setError(validationMessages);

    return validationMessages.length === 0;
  };

  const validateAllSteps = () => {
    let valid = true;
    for (let step = 0; step < stepsState.length; step++) {
      if (!validateStep(step)) {
        valid = false;
        break; //this can only return the validation messages for 1 step so skip the rest if an invalid step is found
      }
    }
    return valid;
  };

  const submitStep = (e) => {
    e.preventDefault();

    //skip validation for steps that have already been tested.
    if (debugSkipSteps.includes(currentStep) || validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
    window.scrollTo(0, 0);
  };

  const finish = (e) => {
    e.preventDefault();

    //if this is a demo, skip final validation (this allows you to jump to the last step to submit without any validation, while still
    //allowing you to test validation on steps other than the review step.)
    if (demo || validateAllSteps()) {
      saveApplication(true);
    } else window.scrollTo(0, 0);
  };

  const backStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const testButton = (e) => {
    e.preventDefault();
    /*axios
      .get("/api/applications?id=5f109a6cb1f9385d383f01cb")
      .then((res) => console.log(res.data));*/

    /*axios
      .post("/applications/add", { values: getValues() })
      .then((res) => console.log(res.data));*/
  };

  const getValues = () => {
    //this no longer gets just the values. We needed more than that to load autosaved applications
    const allowed = ["value"];
    let firstName = null;
    let lastName = null;
    let email = null;

    let valuesOnly = [];
    for (let i = 0; i < stepsState.length; i++) {
      valuesOnly.push([]); //push empty step

      for (let j = 0; j < stepsState[i].rows.length; j++) {
        valuesOnly[i].push([]); //push empty row

        for (let k = 0; k < stepsState[i].rows[j].length; k++) {
          if (stepsState[i].rows[j][k].fieldName === "First Name")
            firstName = stepsState[i].rows[j][k].value;
          if (stepsState[i].rows[j][k].fieldName === "Last Name")
            lastName = stepsState[i].rows[j][k].value;

          if (stepsState[i].rows[j][k].fieldName === "Email")
            email = stepsState[i].rows[j][k].value;

          const filtered = Object.keys(stepsState[i].rows[j][k])
            .filter((key) => {
              return (
                //true allows saving all fields
                true ||
                (allowed.includes(key) &&
                  stepsState[i].rows[j][k].fieldType !== "html") //don't save values for HTML fields as they should never change from the defaults anyway
              );
            })
            .reduce((obj, key) => {
              obj[key] = stepsState[i].rows[j][k][key];
              return obj;
            }, {});
          valuesOnly[i][j].push(filtered);
        }
      }
    }
    return { values: JSON.stringify(valuesOnly), firstName, lastName, email };
  };
  const saveApplication = (complete) => {
    const application_id = Cookies.get("application_id");

    const app = getValues();

    if (application_id) {
      axios
        .post("/api/applications/update", {
          id: application_id,
          update: {
            values: app.values,
            firstName: app.firstName,
            lastName: app.lastName,
            email: app.email,
            incomplete: !complete,
            step: currentStep,
          },
          portalId: portalId,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("Saved.");

            if (complete) {
              //no longer editing this application so remove the cookie
              Cookies.remove("application_id", { path: "" });
              setAuthToken(null);

              //redirect to another site as set in portal settings.
              axios
                .post("/api/portals/getRedirectURL", { portalId })
                .then((res) => window.location.replace(res.data));
            }
          }
        });
    } else {
      axios
        .post("/api/applications/add", {
          form: formID,
          values: app.values,
          portalId: portalId,
          firstName: app.firstName,
          lastName: app.lastName,
          email: app.email,
          incomplete: !complete,
          step: currentStep,
        })
        .then((res) => {
          if (!complete) {
            // Save to localStorage// Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            Cookies.set("application_id", res.data._id, {
              expires: 7,
              path: "",
            });
          } else {
            alert("Saved");
            axios
              .post("/api/portals/getRedirectURL", { portalId })
              .then((res) => window.location.replace(res.data));
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            alert(
              "No response from server. Check your internet connection and try again."
            );
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  const setInvalidForMatchingGroup = (group, invalid) => {
    //get all fields in this matching group
    //let matchingFields = [];
    let newSteps = [...stepsState];
    for (let x of newSteps) {
      for (let y of x.rows) {
        for (let z of y) {
          if (z.matching === group) {
            z.invalid = [[invalid]];
          }
        }
      }
    }

    setStepsState(newSteps);
  };

  const doAutosave = () => {
    //don't autosave if not enabled for this form, or user hasn't changed anything yet
    if (!enableAutosave || !userModified) return;
    if (!stepsState) {
      console.log("Not autosaving...");
      return;
    } else {
      toast.info("Autosaving...", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      saveApplication(false);
    }
  };

  useEffect(() => {
    //autosave after 10 seconds of inactivity (stepsState changes when any field changes, causing the timeout to be reset)
    const autosave = setTimeout(() => {
      doAutosave();
    }, 10000);
    return () => {
      clearTimeout(autosave);
    };
  }, [stepsState]);

  return (
    <>
      {stepsState ? (
        <>
          <Sidebar
            {...{
              logo,
              stepsState,
              formDisplayName,
              currentStep,
              demo,
              setCurrentStep,
            }}
          />
          <div className="main">
            <div className="steps">
              {stepsState.map((val, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div
                      className={
                        "step" + (currentStep >= idx ? " current" : "")
                      }
                    >
                      <div
                        className={"stepNumber"}
                        /*if this is a demo you can skip steps by clicking on them in the sidebar or at the top */
                        onClick={() => (demo ? setCurrentStep(idx) : null)}
                      >
                        {`0${idx + 1}`}
                      </div>
                      <div className="stepName">{val.stepName}</div>
                    </div>
                    {idx !== stepsState.length - 1 ? (
                      <span
                        className={`stepline ${
                          currentStep >= idx + 1 ? "current" : ""
                        }`}
                      ></span>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            <div
              className="errors"
              style={
                error.length > 0 ? { display: "block" } : { display: "none" }
              }
            >
              {error.map((val, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="error">{val.message}</div>
                  </React.Fragment>
                );
              })}
            </div>

            <form onSubmit={handleSubmit} autoComplete="on">
              {stepsState.map((val, idx) => {
                let stepId = `step-${idx}`;
                return (
                  <div
                    key={idx}
                    id={stepId}
                    style={
                      currentStep === idx ||
                      stepsState[currentStep || 0].stepType === "review"
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="form">
                      {val.rows.map((row, rowIndex) => {
                        return (
                          <React.Fragment key={rowIndex}>
                            <Row
                              key={rowIndex}
                              rowIndex={rowIndex}
                              row={row}
                              stepIndex={idx}
                              handleChange={handleChange}
                              handleCheckboxChange={handleCheckboxChange}
                              handleDatePickerChange={handleDatePickerChange}
                              onBlur={handleValidateField}
                              updateSteps={updateStepsWholeField}
                            ></Row>
                          </React.Fragment>
                        );
                      })}

                      <div className="buttons">
                        {currentStep > 0 ? (
                          <button
                            type="button"
                            className="button previous"
                            onClick={backStep}
                            style={
                              currentStep === idx
                                ? { display: "inline-block" }
                                : { display: "none" }
                            }
                          >
                            Previous
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </button>
                        ) : null}
                        <button
                          className="button next"
                          type="submit"
                          onClick={
                            idx !== stepsState.length - 1 ? submitStep : finish
                          }
                          style={
                            currentStep === idx
                              ? { display: "inline-block" }
                              : { display: "none" }
                          }
                        >
                          {idx !== stepsState.length - 1
                            ? "Next Step"
                            : "Finish"}
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </>
      ) : (
        <div>{loadingState}</div>
      )}
      <ToastContainer />
    </>
  );
}
