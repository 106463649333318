import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

function CustomCSS() {
  const auth = useSelector((state) => state.auth);
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    selectedPortal !== undefined ? selectedPortal : auth.user.PortalId;

  console.log(`Loading custom CSS from ${portal}`);

  const [CSS, setCSS] = useState("");

  useEffect(() => {
    if (portal !== undefined) {
      axios
        .post("/api/portals/getPortalCustomCSS", { portalId: portal })
        .then((res) => {
          const customCSS = res.data;
          setCSS(customCSS);
        });
    }
  }, [portal]);

  return <style>{CSS}</style>;
}

export default CustomCSS;
