import React from "react";

const getStepIcon = (stepName) => {
  switch (stepName.toLowerCase()) {
    case "personal info":
      return "/images/personal.png";
    case "loan info":
      return "/images/loans.png";
    case "assets":
      return "/images/assets.png";
    case "debts":
      return "/images/debt.png";
    case "review":
      return "/images/review.png";
    default:
      return null;
  }
};

function Sidebar({
  logo,
  stepsState,
  formDisplayName,
  currentStep,
  demo,
  setCurrentStep,
}) {
  return (
    <div className="sidebar">
      <h1>{logo ? <img src={logo} /> : formDisplayName}</h1>
      <div className="sidebarsteps">
        {stepsState.map((val, idx) => {
          return (
            <React.Fragment key={idx}>
              <div
                className={
                  "sidebarStepNumber" + (currentStep === idx ? " current" : "")
                }
                /*if this is a demo you can skip steps by clicking on them in the sidebar or at the top */
                onClick={() => (demo ? setCurrentStep(idx) : null)}
              >
                {`0${idx + 1}`}
              </div>
              {idx !== stepsState.length - 1 ? (
                <span className={`divider`}>-----</span>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="sidebarsteplogo">
        <img src={getStepIcon(stepsState[currentStep].stepName)} />
      </div>
      <div className="sidebarstepname">{stepsState[currentStep].stepName}</div>
    </div>
  );
}

export default Sidebar;
