import React from "react";

function ApplicationTable(props) {
  const { id, className, applications, changeSort, sortIndicator } = props;
  const tableHeaderStyles = {
    fontWeight: "bold",
  };

  return (
    <div
      id={id}
      className={className}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 12vw)",
      }}
    >
      <div
        className="tableHeader"
        style={tableHeaderStyles}
        onClick={() => changeSort("StartTime")}
      >
        Date Applied {sortIndicator("StartTime")}
      </div>
      <div
        className="tableHeader"
        style={tableHeaderStyles}
        onClick={() => changeSort("Status")}
      >
        Status {sortIndicator("Status")}
      </div>
      <div
        className="tableHeader"
        style={tableHeaderStyles}
        onClick={() => changeSort("FirstName")}
      >
        First Name {sortIndicator("FirstName")}
      </div>
      <div
        className="tableHeader"
        style={tableHeaderStyles}
        onClick={() => changeSort("LastName")}
      >
        Last Name {sortIndicator("LastName")}
      </div>
      <div className="tableHeader" style={tableHeaderStyles}>
        View / Edit
      </div>
      <div className="tableHeader" style={tableHeaderStyles}>
        Delete
      </div>
      <div className="tableHeader" style={tableHeaderStyles}>
        Modified By
      </div>
      {applications.map((application, idx) => {
        const firstName = application.legacy
          ? application.ApplicantInfo.FirstName
          : application.firstName
          ? application.firstName
          : "Not found in form.";
        const lastName = application.legacy
          ? application.ApplicantInfo.LastName
          : application.lastName
          ? application.lastName
          : "Not found in form.";
        let date = new Date(
          application.legacy ? application.EndTime : application.createdAt
        );
        if (!date || date.getFullYear() === 0)
          date = new Date(application.StartTime);
        return (
          <React.Fragment key={idx}>
            <div className="tableCell">{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</div>
            <div className="tableCell">
              {application.Status || application.status}
            </div>
            <div className="tableCell">{firstName}</div>
            <div className="tableCell">{lastName}</div>
            <div className="tableCell">
              <a href={`/viewapplication?application=${application._id}`}>
                View / Edit
              </a>
            </div>
            <div className="tableCell">Delete</div>
            <div className="tableCell">
              {application.ModifiedBy ? application.ModifiedBy : "Nobody"}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default ApplicationTable;
