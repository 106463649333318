//types of validation:
//whether anything is in the field (required)
//the value of the field itself (regex, email)
//whether it matches other fields with the same number (matching)

class Validation {
  static validateRegex(value, regex) {
    return new RegExp(regex).test(value);
  }

  static validateEmail(value) {
    return Validation.validateRegex(
      value,
      new RegExp("^\\w+@[\\w-]+\\.[a-zA-Z]{2,3}$")
    );
  }

  static validatePhone(value) {
    return Validation.validateRegex(
      value,
      new RegExp(
        "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
      )
    );
  }

  static getValidationMessage(field, state, setState) {
    const fieldName = field.fieldDisplayName
      ? field.fieldDisplayName
      : field.fieldName;
    let valid = true;
    let invalid = [];

    if (field.required) {
      invalid = [[!field.value]];
      Validation.updateFieldInvalid(field, invalid, state, setState);
      if (!field.value) return [fieldName + " is required."];
    }
    if (!field.required && !field.value) {
      invalid = [[false]];
      Validation.updateFieldInvalid(field, invalid, state, setState);
      return "";
    }

    let values;
    let validation;
    let regex;
    let regexErrors;
    let messages = [];
    if (field.validation) {
      if (Array.isArray(field.value)) {
        values = field.value;
        validation = field.validation;
        regex = field.regex;
        regexErrors = field.regexErrorMessage;
      } else {
        //make these arrays anyway
        values = [[field.value]];
        validation = [field.validation];
        regex = [field.regex];
        regexErrors = [field.regexErrorMessage];
      }
      for (let i = 0; i < values.length; i++) {
        invalid.push([]);
        for (let j = 0; j < values[i].length; j++) {
          switch (validation[j]) {
            case "required":
              valid = values[i][j] !== "";
              messages.push(valid ? "" : fieldName + " is required.");
              break;
            case "regex":
              valid = Validation.validateRegex(values[i][j], regex[j]);
              messages.push(valid ? "" : regexErrors[j]);
              break;
            case "email":
              valid = Validation.validateEmail(values[i][j]);
              messages.push(valid ? "" : fieldName + " must be a valid email.");
              break;
            case "phone":
              valid = Validation.validatePhone(values[i][j]);
              messages.push(
                valid ? "" : fieldName + " must be a valid phone number."
              );
              break;
            case "":
              messages.push("");
              break;
            default:
              messages.push(
                fieldName +
                  " couldn't be validated due to invalid validation type."
              );
          }
          invalid[i].push(!valid);
        }
      }

      Validation.updateFieldInvalid(field, invalid, state, setState);
      return messages;
    } else return "";
  }

  static validateField(field, validatedMatchingGroups, state, setState) {
    let validationMessages = [];
    let fieldInvalid = false; //track whether this field has already been marked invalid
    if (field.conditionalDisplay && !field.visible) return "";
    let messages = Validation.getValidationMessage(field, state, setState);
    //turn the string messages into messages that contain fieldName so they can more easily be identified and removed later
    for (let x in messages) {
      if (messages[x] !== "") {
        validationMessages.push({
          message: messages[x],
          fieldName: field.fieldName,
        });
        fieldInvalid = true;
      }
    }

    //has !fieldInvalid so we don't generate multiple messages/do extra validation work for a field we already know is invalid
    //need to compare matching to undefined because field.matching would be falsy if matching group is 0
    //lastly, don't validate if the matching group has already been validated
    if (
      !fieldInvalid &&
      field.matching !== undefined &&
      !validatedMatchingGroups.includes(field.matching)
    ) {
      let message = Validation.validateMatching(field, state);
      validationMessages.push(message);
      if (message && message.message) {
        fieldInvalid = true;
      }
      validatedMatchingGroups.push(field.matching);
    }
    return validationMessages;
  }

  static validateMatching(field, state) {
    //get all fields in this matching group
    let matchingFields = [];
    for (let x of state) {
      for (let y of x.rows) {
        for (let z of y) {
          if (z.matching === field.matching) {
            matchingFields.push(z);
          }
        }
      }
    }

    let errorString = "";
    let index = 0;
    let error = false;
    for (let m of matchingFields) {
      if (index > 0) {
        errorString += " and ";
      }
      errorString += m.fieldName;
      if (m.value !== matchingFields[0].value) {
        error = true;
      }
      index++;
    }
    errorString += " must match.";
    if (error)
      return {
        fieldName: field.fieldName,
        message: errorString,
        matching: field.matching,
      };

    return null;
  }

  static updateFieldInvalid(field, invalid, state, callback) {
    let newSteps = [...state];
    let newField = newSteps[field.x].rows[field.y][field.z];
    newField.invalid = invalid;
    callback(newSteps);
  }
}

export default Validation;
