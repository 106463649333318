import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Table } from "./Table.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";

function addYears(date, years) {
  const copy = new Date(Number(date));
  copy.setFullYear(date.getFullYear() + years);
  return copy;
}

export function Field(props) {
  const changeAndBlur = (e) => {
    props.handleChange(e);
    props.onBlur(e);
  };

  let field = props.field;
  let fieldId = props.fieldId;
  let fieldType = field.fieldType;
  let inputFields = ["text", "submit", "number"]; //fields that use the "input" HTML tag
  let invalid = field.invalid && field.invalid[0] && field.invalid[0][0];
  let label = (
    <label htmlFor={fieldId}>
      {field.fieldDisplayName ? field.fieldDisplayName : field.fieldName}{" "}
      {field.required ? <span className="required">*</span> : null}
    </label>
  );

  if (field.conditionalDisplay && !field.visible) return <></>;
  if (fieldType === "html") return ReactHtmlParser(field.value);
  else if (inputFields.includes(fieldType))
    return (
      <div className={`field ${fieldType}`}>
        {label}
        {field.validation === "phone" ? (
          <>
            <FontAwesomeIcon icon={faPhoneFlip} />
          </>
        ) : (
          <></>
        )}
        <input
          type={field.fieldType}
          name={fieldId}
          data-id={fieldId}
          data-testid={fieldId}
          id={fieldId}
          value={props.value}
          onChange={props.handleChange}
          maxLength={field.maxLength}
          className={invalid ? "invalid" : ""}
          onBlur={props.onBlur}
          min={field.min}
          max={field.max}
          placeholder={field.placeholder}
          aria-label={field.fieldName}
          disabled={props.disabled}
        />
      </div>
    );
  else if (fieldType === "textarea") {
    return (
      <div className="field">
        {label}
        <textarea
          type={field.fieldType}
          name={fieldId}
          data-id={fieldId}
          id={fieldId}
          value={props.value}
          onChange={props.handleChange}
          maxLength={field.maxLength}
          className={invalid ? "invalid" : ""}
          onBlur={props.onBlur}
          min={field.min}
          max={field.max}
          placeholder={field.placeholder}
          disabled={props.disabled}
        ></textarea>
      </div>
    );
  } else if (fieldType === "datepicker") {
    let maxDate = addYears(new Date(), field.maxDateAddYears);
    return (
      <div className="field">
        {label}
        {props.disabled ? (
          <input type="text" disabled={true} value={props.value}></input>
        ) : (
          <DatePicker
            name={fieldId}
            data-id={fieldId}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            id={fieldId}
            selected={props.disabled ? new Date(props.value) : props.value} //when it's disabled it's because it's being viewed in admin side and the data from the database needs to be converted to a date
            onChange={
              props.handleDatePickerChange
                ? props.handleDatePickerChange.bind(this, fieldId)
                : null
            }
            maxDate={maxDate}
            placeholderText={field.placeholder}
            disabled={props.disabled}
          ></DatePicker>
        )}
      </div>
    );
  } else if (fieldType === "select") {
    return (
      <div className="field">
        {label}
        <select
          name={fieldId}
          data-id={fieldId}
          id={fieldId}
          selected={props.value}
          onChange={changeAndBlur}
          className={invalid ? "invalid" : ""}
          defaultValue={props.value}
          disabled={props.disabled}
        >
          {field.options.map((option, idx) => {
            return (
              <option key={idx} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else if (fieldType === "radio") {
    return (
      <div className="field radio">
        {label}
        {field.options.map((option, idx) => {
          let buttonId = fieldId + "-" + option.name;
          return (
            <React.Fragment key={idx}>
              <label className="radiobuttonlabel" htmlFor={buttonId}>
                {option.name}
              </label>
              <input
                type="radio"
                value={option.value}
                checked={option.value === props.value}
                id={buttonId}
                name={field.fieldName}
                onChange={changeAndBlur}
                disabled={props.disabled}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  } else if (fieldType === "table") {
    return (
      <div className="field fieldTable">
        {field.hideLabel ? null : label}
        <Table
          id={fieldId}
          field={field}
          updateSteps={props.updateSteps}
          onBlur={props.onBlur}
          invalid={field.invalid}
          disabled={props.disabled}
        ></Table>
      </div>
    );
  } else if (fieldType === "checkbox") {
    return (
      <div className="field fieldCheckbox">
        {field.hideLabel ? null : label}
        <input
          type="checkbox"
          id={fieldId}
          checked={props.value}
          onChange={props.handleCheckboxChange}
          field={field}
          onBlur={props.onBlur}
          invalid={field.invalid}
          disabled={props.disabled}
        ></input>
      </div>
    );
  } else return null;
}
