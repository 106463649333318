import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PortalSelector } from "../PortalSelector";
import axios from "axios";
import DatePicker from "react-datepicker";
import ApplicationTable from "../ApplicationTable";
import Header from "../Header";

function Admin(props) {
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      props.history.push("/login");
    }
  });

  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    selectedPortal != undefined ? selectedPortal : auth.user.PortalId;

  const filterOptions = ["Status", "Date", "Last Name"];
  const statusOptions = [
    "All Completed Applications",
    "Unread",
    "Under Review",
    "Incomplete",
    "Approved",
    "Not Approved",
    "Terminated on Confirmation Page",
  ];

  const [applications, setApplications] = useState([]);
  const [legacyApplications, setLegacyApplications] = useState([]);

  const [filterOption, setFilterOption] = useState(filterOptions[0]);
  const [statusFilter, setStatusFilter] = useState(statusOptions[0]);
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");

  const [sortBy, setSortBy] = useState("StartTime");
  const [sortOrder, setSortOrder] = useState("DESC");

  useEffect(() => {
    axios
      .post("/api/applications/getApplications", { portalId: portal })
      .then((res) => {
        setApplications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post("/api/applications/getLegacyApplications", {
        portalId: portal,
      })
      .then((res) => {
        setLegacyApplications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [portal]);

  const newFieldNames = (name) => {
    //sorting by clicking table headers uses the old names for these fields, so this function can translate them to the new names for non-legacy applications
    switch (name) {
      case "FirstName":
        return "firstName";
      case "LastName":
        return "lastName";
      case "StartTime":
        return "createdAt";
      case "Status":
        return "status";
    }
  };

  const compare = (a, b, sortOrder) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
      console.log("-------");
      console.log("a < b");
      console.log(a);
      console.log(b);
      return sortOrder === "DESC" ? 1 : -1;
    }
    if (a === b) {
      console.log("-------");
      console.log("a === b");
      console.log(a);
      console.log(b);
      return 0;
    }

    console.log("-------");
    console.log("a > b");
    console.log(a);
    console.log(b);
    return sortOrder === "DESC" ? -1 : 1;
  };

  const applySort = (array, debugSkip = false) => {
    if (debugSkip || array.length === 0) return array;
    console.log(array);
    const sortField =
      array[0].legacy !== undefined && array[0].legacy === false
        ? newFieldNames(sortBy)
        : sortBy;

    return array.sort((a, b) => {
      if (sortField === "FirstName" || sortField === "LastName") {
        //these values are in ApplicantInfo so set a and b to their ApplicantInfo before comparing
        a = a.ApplicantInfo;
        b = b.ApplicantInfo;
      }
      if (!a || !b) return 0;
      return compare(a[sortField], b[sortField], sortOrder);
    });
  };

  const applyFilters = (array) => {
    return array.filter((application) => {
      switch (filterOption) {
        case filterOptions[0]:
          switch (statusFilter) {
            case statusOptions[0]:
              return true;
            default:
              return application.Status === statusFilter;
          }
        case filterOptions[1]:
          if (application.StartTime) {
            let date = new Date(application.StartTime);

            return (
              date > new Date(dateFilterStart) && date < new Date(dateFilterEnd)
            );
          }
          break;
        case filterOptions[2]:
          const regex = new RegExp(lastNameFilter, "i");
          if (
            lastNameFilter === "" ||
            application.ApplicantInfo.LastName.match(regex)
          )
            return true;
          break;
      }
    });
  };

  const changeSort = (newSortBy) => {
    if (sortBy === newSortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortBy(newSortBy);
    }
  };

  const sortIndicator = (sort) => {
    return sortBy === sort ? <div>{sortOrder}</div> : "";
  };

  const getDate = (date) => {
    //the default/empty date from mongo wouldn't work with the datepicker, this solves it...
    if (date) {
      if (date instanceof Date) {
        return date;
      } else {
        date = new Date(date);
        if (date.getFullYear() === 0) {
          return null; //return null if this is the default/empty date from mongo
        } else return date;
      }
    } else return null;
  };

  const createUser = () => {
    axios.post("/api/users/create", {
      userName: "thecreditunion",
      password: "password",
      PortalId: 0,
      email: "kmurphy@thecreditu.ca ",
    });
  };

  return (
    <>
      <Header />
      <div style={{ margin: "0 auto", width: "90%", padding: "20px 0" }}>
        <PortalSelector />
        <div>
          Filter:{" "}
          <select onChange={(e) => setFilterOption(e.target.value)}>
            {filterOptions.map((option) => {
              return <option value={option}>{option}</option>;
            })}
          </select>
          {filterOption === filterOptions[0] ? (
            <select onChange={(e) => setStatusFilter(e.target.value)}>
              {statusOptions.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          ) : null}
          {filterOption === filterOptions[1] ? (
            <>
              <DatePicker
                selected={getDate(dateFilterStart)}
                onChange={(date) => {
                  setDateFilterStart(date);
                }}
                placeholderText="Choose start date"
                showYearDropdown
                showMonthDropdown
                startDate={Date.now()}
              ></DatePicker>
              <DatePicker
                selected={getDate(dateFilterEnd)}
                onChange={(date) => {
                  setDateFilterEnd(date);
                }}
                placeholderText="Choose end date"
                showYearDropdown
                showMonthDropdown
                startDate={Date.now()}
              ></DatePicker>
            </>
          ) : null}
          {filterOption === filterOptions[2] ? (
            <input
              type="text"
              value={lastNameFilter}
              onChange={(e) => setLastNameFilter(e.target.value)}
            />
          ) : null}
        </div>

        <ApplicationTable
          id="applications"
          className="applicationsTable"
          applications={applySort(applyFilters(applications))}
          changeSort={changeSort}
          sortIndicator={sortIndicator}
        />
        {legacyApplications.length > 0 ? (
          <>
            <h1>Legacy Applications</h1>
            <p>
              Applications from before the redesign are available in case they
              are needed, but not much effort has been made in formatting them.
              Let us know if making these look nicer is important to you.
            </p>
            <ApplicationTable
              id="legacy-applications"
              className="applicationsTable"
              applications={applySort(applyFilters(legacyApplications))}
              changeSort={changeSort}
              sortIndicator={sortIndicator}
            />
          </>
        ) : null}

        {/* <button onClick={() => createUser()}>Create User</button> */}
      </div>
    </>
  );
}

export default Admin;
