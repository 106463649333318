import React from "react";

export function Table(props) {
  const rows = [];
  let field = props.field;
  if (field.addRemoveRows && props.disabled) field.rows = field.value.length; //if this is an adjustable table and is being displayed only, show only the rows that were actually filled out
  let fields = [];

  //if we want to display the total of the first column, we need to add an additional column to the left of that to display the "Total:" label
  let totalColumn = field.displayTotals && field.displayTotals.includes(0);
  let headers = [...props.field.headers];

  if (totalColumn) {
    headers.unshift("");
  }

  //creates an empty array if value is not specified
  const getDefaultValue = () => {
    let value = [];
    for (let row = 0; row < field.rows; row++) {
      value.push([]);
      for (let col = 0; col < field.columns; col++) {
        value[row].push("");
      }
    }
    return value;
  };

  const getValue = () => {
    return field.value ? [...field.value] : getDefaultValue();
  };

  field.value = getValue();

  const handleChange = (i, j, e) => {
    let value = getValue();
    value[i][j] = e.target.value;
    field.value = value;
    props.updateSteps(props.id, field);
  };

  const calculateColumnTotal = (column) => {
    let value = getValue();
    let total = 0;

    for (let row = 0; row < value.length; row++) {
      for (let col = 0; col < value[row].length; col++) {
        if (col === column && value[row][col]) {
          let val = parseFloat(value[row][col]);
          if (!isNaN(val)) total += val;
        }
      }
    }
    return total.toFixed(2);
  };

  const addRow = () => {
    field.rows++;
    if (field.newRowDefault) {
      field.value.push([...field.newRowDefault]);
    } else {
      //fill row with empty strings so it's always considered "controlled"
      let rowValues = [];
      for (var i = 0; i < field.columns; i++) rowValues.push("");
      field.value.push(rowValues);
    }
    props.updateSteps(props.id, field);
  };

  const removeRow = () => {
    field.value.pop();
    field.rows--;
    props.updateSteps(props.id, field);
  };
  for (let i = 0; i < props.field.rows; i++) {
    fields = [];
    let field = null;
    if (totalColumn) {
      fields.push(<td></td>);
    }

    let types = props.field.types;
    //if types aren't the same for all rows each row will have an array
    if (Array.isArray(types[i])) {
      types = types[i];
    }
    //if this row doesn't have its own array, but there was a row that did, repeat the last row
    else if (Array.isArray(types[types.length - 1]))
      types = types[types.length - 1];

    let options = props.field.options;

    for (let j = 0; j < props.field.columns; j++) {
      let value =
        props.field.value && props.field.value[i] && props.field.value[i][j]
          ? props.field.value[i][j]
          : "";
      if (value === "nocell") {
        continue;
      }
      switch (types[j]) {
        case "text":
          field = (
            <td key={i + "-" + j}>
              <input
                id={props.id + "-subfield-" + i + "-" + j}
                type="text"
                value={value}
                onChange={handleChange.bind(this, i, j)}
                onBlur={props.onBlur}
                className={
                  props.invalid && props.invalid[i] && props.invalid[i][j]
                    ? "invalid"
                    : ""
                }
                placeholder={
                  props.field.placeholder &&
                  props.field.placeholder[i] &&
                  props.field.placeholder[i][j]
                    ? props.field.placeholder[i][j]
                    : ""
                }
                disabled={props.disabled}
              ></input>
            </td>
          );
          break;
        case "html":
          field = <td key={i + "-" + j}>{value}</td>;
          break;
        case "select":
          //check if options is a 3D array, if so get the options for this row and column, otherwise only column is needed
          if (Array.isArray(options[0][0])) {
            options = options[i][j];
          } else options = options[j];

          field = (
            <td key={i + "-" + j}>
              <select
                id={props.id + "-subfield-" + i + "-" + j}
                data-id={props.id + "-subfield-" + i + "-" + j}
                selected={value}
                onChange={handleChange.bind(this, i, j)}
                className={
                  props.invalid && props.invalid[i] && props.invalid[i][j]
                    ? "invalid"
                    : ""
                }
                defaultValue={props.value}
                disabled={props.disabled}
              >
                {options.map((option, idx) => {
                  return (
                    <option key={idx} value={option.value}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </td>
          );
          break;
        default:
          field = <td key={i + "-" + j}></td>;
      }

      fields.push(field);
    }
    /* remove specific table row (need to work out the bugs)
    fields.push(
      <td key={i}>
        <button data-id={i} onClick={removeRow}>
          -
        </button>
      </td>
    );*/
    rows.push(<tr key={"row-" + i}>{fields}</tr>);
  }

  let totals = <></>;
  if (field.displayTotals) {
    let cells = [];
    if (totalColumn) {
      cells.push(<td>Total:</td>);
    }
    for (let j = 0; j < props.field.columns; j++) {
      if (j === 0 && !totalColumn) {
        cells.push(<td key="total">Total:</td>);
        continue;
      }
      if (field.displayTotals.includes(j)) {
        cells.push(<td key={"total-" + j}>{calculateColumnTotal(j)}</td>);
      } else {
        cells.push(<td key={"no-total-" + j}></td>);
      }
    }
    totals = <tr>{cells}</tr>;
  }

  return (
    <React.Fragment>
      <table id={props.id}>
        <thead>
          <tr>
            {headers.map((val, idx) => {
              return <th key={"header-" + val}>{val}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rows}
          {totals}
          {field.addRemoveRows && !props.disabled ? (
            <tr>
              <td>
                <button onClick={addRow}>+</button>
                <button onClick={removeRow}>-</button>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </React.Fragment>
  );
}
