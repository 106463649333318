import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ImageUploader from "./ImageUploader";
import Header from "./Header";

function Settings(props) {
  const auth = useSelector((state) => state.auth);
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    selectedPortal != undefined ? selectedPortal : auth.user.PortalId;

  const [loanOfficerEmail, setLoanOfficerEmail] = useState("");
  const [sendEmailFrom, setSendEmailFrom] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  const [websiteLinkText, setWebsiteLinkText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [useAuthentication, setUseAuthentication] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [password, setPassword] = useState("");

  //appearance settings
  const [logo, setLogo] = useState("");
  const [logoErrorMessage, setLogoErrorMessage] = useState("");
  const [customCSS, setCustomCSS] = useState("");

  useEffect(() => {
    axios
      .post("/api/portals/getPortalSettings", { portalId: portal })
      .then((res) => {
        const settings = res.data;
        setLoanOfficerEmail(settings.loanOfficerEmail);
        setSendEmailFrom(settings.sendEmailFrom);
        setDisplayName(settings.displayName);
        setRedirectURL(settings.redirectURL);
        setWebsiteLinkText(settings.websiteLinkText);
        setEmailSubject(settings.emailSubject);
        setEmailBody(settings.emailBody);
        setUseAuthentication(settings.useAuthentication);
        setHost(settings.host);
        setPort(settings.port);
        setPassword(settings.password);
        setLogo(settings.logo);
        setCustomCSS(settings.customCSS);
      });
  }, []);

  const saveSettings = () => {
    axios
      .post("/api/portals/savePortalSettings", {
        portalId: portal,
        loanOfficerEmail,
        sendEmailFrom,
        displayName,
        redirectURL,
        websiteLinkText,
        emailSubject,
        emailBody,
        useAuthentication,
        host,
        port,
        password,
        logo,
        customCSS,
      })
      .then((res) => {
        if (res.status === 200) alert("Saved");
      });
  };

  const back = () => {
    props.history.push("/admin");
  };

  return (
    <>
      <Header />
      <div style={{ padding: "10px" }}>
        <fieldset>
          <legend>Functionality</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridGap: "2px",
            }}
          >
            <label htmlFor={"loan-officer-email"}>Loan Officer Email:</label>
            <input
              type="text"
              id="loan-officer-email"
              value={loanOfficerEmail}
              onChange={(e) => setLoanOfficerEmail(e.target.value)}
            ></input>
            <label htmlFor={"send-email-from"}>Send Email From:</label>
            <input
              type="text"
              id="send-email-from"
              value={sendEmailFrom}
              onChange={(e) => setSendEmailFrom(e.target.value)}
            ></input>
            <label htmlFor={"display-name"}>Display Name:</label>
            <input
              type="text"
              id="display-name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            ></input>

            <label htmlFor={"redirect-url"}>Redirect URL:</label>
            <input
              type="text"
              id="redirect-url"
              value={redirectURL}
              onChange={(e) => setRedirectURL(e.target.value)}
            ></input>

            <label htmlFor={"website-link-text"}>Website Link Text:</label>
            <input
              type="text"
              id="website-link-text"
              value={websiteLinkText}
              onChange={(e) => setWebsiteLinkText(e.target.value)}
            ></input>

            <label htmlFor={"email-subject"}>Email Subject:</label>
            <input
              type="text"
              id="email-subject"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            ></input>

            <label htmlFor={"email-body"}>Email Body:</label>
            <textarea
              id="email-body"
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
            ></textarea>
          </div>
          {
            /*no longer needed as SendInBlue is used now*/
            false && (
              <fieldset style={{ display: "none" }}>
                <legend>Email Authentication Settings</legend>
                <label htmlFor={"use-authentication"}>
                  Use Authentication:
                </label>
                <input
                  type="checkbox"
                  id="use-authentication"
                  checked={useAuthentication}
                  onChange={(e) => setUseAuthentication(e.target.checked)}
                ></input>
                <br />
                <label htmlFor={"host"}>Host:</label>
                <input
                  type="text"
                  id="host"
                  checked={host}
                  onChange={(e) => setHost(e.target.value)}
                ></input>

                <label htmlFor={"port"}>Port:</label>
                <input
                  type="text"
                  id="port"
                  checked={port}
                  onChange={(e) => setPort(e.target.value)}
                ></input>

                <label htmlFor={"password"}>Password:</label>
                <input
                  type="text"
                  id="password"
                  checked={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </fieldset>
            )
          }
        </fieldset>
        <fieldset>
          <legend>Appearance</legend>
          <label htmlFor={"logo"}>Logo:</label>
          <ImageUploader
            imageSrc={logo}
            updateImage={setLogo}
            PortalId={portal}
            setMessage={setLogoErrorMessage}
          />
          <span style={{ color: "red" }}>{logoErrorMessage}</span>
          <label htmlFor={"customCSS"}>Custom CSS:</label>
          <textarea
            type="text"
            id="customCSS"
            value={customCSS}
            style={{ height: "300px", width: "600px" }}
            onChange={(e) => setCustomCSS(e.target.value)}
          />
        </fieldset>
        <input
          className="button backButton"
          type="button"
          value="Back"
          onClick={() => back()}
        ></input>
        <input
          className="button submitButton"
          type="submit"
          value="Submit"
          onClick={() => saveSettings()}
        ></input>
      </div>
    </>
  );
}

export default Settings;
