import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, setCurrentUser } from "../../actions/authActions";

import { useAuth0 } from "@auth0/auth0-react";

import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS } from "../../actions/types"; // Register User

import Header from "../Header";

function Login(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  console.log(user);

  useEffect(() => {
    if (isAuthenticated) {
      getUserMetadata();
    }
    if (auth.isAuthenticated) {
      props.history.push("/admin");
    }
  });

  const getUserMetadata = async () => {
    const domain = "rgisolutions.us.auth0.com";

    try {
      console.log("trying getAccessTokenSilently");
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      axios
        .post("/api/users/getToken", { userDetailsByIdUrl, accessToken })
        .then((res) => {
          // Save to localStorage// Set token to localStorage
          const { token } = res.data;
          localStorage.setItem("jwtToken", token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          dispatch(setCurrentUser(decoded));
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } catch (e) {
      if (e.error === "login_required") {
        //loginWithRedirect();
      }
      console.log(e.message);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      userName: userName,
      password: password,
    };

    dispatch(loginUser(userData));
  };

  return (
    <>
      <Header></Header>
      <div id="LoginBox">
        <form noValidate onSubmit={onSubmit}>
          <div style={{ textAlign: "center" }}>
            <h1>{errors.loginerror || "Please sign in"}</h1>
            <input
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              id="username"
              type="text"
              placeholder="Username"
              className={errors.loginerror ? "invalid" : ""}
            />
            <br />
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              name="txtPassword"
              type="password"
              id="txtPassword"
              placeholder="Password"
              className={errors.loginerror ? "invalid" : ""}
            />
            <br />
            <Link to="/resetpassword" className="btn-flat waves-effect">
              Forgot Password?
            </Link>
            <br />
            <input
              type="submit"
              name="btnLogin"
              value="Log In"
              id="btnLogin"
              className="LoginButton"
            />
          </div>
        </form>
        {/*having this in the form made it log in without auth0*/}
        <button className="Auth0Button" onClick={() => loginWithRedirect()}>
          Log In With Auth0
        </button>
      </div>
    </>
  );
}

export default Login;
