import { SET_SELECTED_PORTAL } from "../actions/types";
const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_PORTAL:
      return {
        ...state,
        portal: action.payload,
      };
    default:
      return state;
  }
}
