export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SELECTED_PORTAL = "SET_SELECTED_PORTAL";

export const AUTHENTICATION_PASSWORD_RESET_HASH_CREATED =
  "AUTHENTICATION_PASSWORD_RESET_HASH_CREATED";
export const AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE =
  "AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE";
export const AUTHENTICATION_PASSWORD_RESET_CLEAR =
  "AUTHENTICATION_PASSWORD_RESET_CLEAR";
