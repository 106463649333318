import {
  SET_CURRENT_USER,
  USER_LOADING,
  AUTHENTICATION_PASSWORD_RESET_CLEAR,
  AUTHENTICATION_PASSWORD_RESET_HASH_CREATED,
  AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE,
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  isPasswordReset: false,
  error: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATION_PASSWORD_RESET_CLEAR:
      return { ...state, isPasswordReset: false };
    case AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE:
      return { ...state, isPasswordReset: false, error: action.payload };
    case AUTHENTICATION_PASSWORD_RESET_HASH_CREATED:
      return { ...state, isPasswordReset: true };
    default:
      return state;
  }
}
