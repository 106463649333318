import React, { useState, useEffect } from "react";
import { addCoordinates } from "./LoanApplication";
import axios from "axios";
import * as QueryString from "query-string";

import { Row } from "./Row";

export function ViewApplication(props) {
  let steps = null;
  const [stepsState, setStepsState] = useState(null);
  const [loadingState, setLoadingState] = useState("Loading...");
  const [application, setApplication] = useState(null);

  const [reviewNotes, setReviewNotes] = useState("");
  const [decisionNotes, setDecisionNotes] = useState("");
  const [status, setStatus] = useState("");

  const statusOptions = [
    "Unread",
    "Under Review",
    "Approved",
    "Not Approved",
    "Terminated on Confirmation Page",
  ];

  const params = QueryString.parse(props.location.search);

  useEffect(() => {
    if (stepsState === null) getForm(steps);
  }, []);

  const addValues = (steps, application) => {
    for (let x = 0; x < steps.length; x++) {
      let rows = steps[x].rows;
      for (let y = 0; y < rows.length; y++) {
        let row = rows[y];
        for (let z = 0; z < row.length; z++) {
          let field = row[z];
          if (application[x][y]) {
            field.value = application[x][y][z].value;
            field.visible = application[x][y][z].visible;
          }
        }
      }
    }
    return steps;
  };
  const getForm = (steps) => {
    axios
      .post("/api/applications/findOne", { _id: params.application })
      .then((res) => {
        const application = res.data;
        if (application.legacy) {
          setApplication(application);
        } else {
          axios
            .post("/api/forms/getById", { _id: application.form })
            .then((res) => {
              const form = res.data;
              steps = addValues(form.steps, JSON.parse(application.values));
              setApplication(application);
              setReviewNotes(application.reviewNotes);
              setDecisionNotes(application.decisionNotes);
              setStatus(application.status);
              setStepsState(steps);
            });
        }
        setLoadingState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveApplication = () => {
    axios
      .post("/api/applications/update", {
        id: application._id,
        update: { reviewNotes, decisionNotes, status },
      })
      .then((res) => {
        if (res.status === 200) alert("Saved.");
      });
  };

  const displayObject = (object, recursionLevel) => {
    return Object.keys(object).map((key) => {
      const value = object[key];
      return (
        <div>
          <strong>{key}:</strong>{" "}
          {typeof value === "object" && value !== null ? (
            <div style={{ marginLeft: `${(recursionLevel + 1) * 20}px` }}>
              {displayObject(value, recursionLevel + 1)}
            </div>
          ) : typeof value === "boolean" ? (
            value ? (
              "true"
            ) : (
              "false"
            )
          ) : (
            value
          )}
        </div>
      );
    });
  };

  return (
    <>
      To save as PDF, open the Print dialog (Ctrl+P) and change destination to
      "Save as PDF".
      <>
        <div>
          {application && application.legacy ? (
            <>{displayObject(application, 0)}</>
          ) : null}
          {stepsState ? (
            <>
              <hr />
              <span className="StepHeaderLabel">Loan Officer Notes</span>
              <hr />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "600px",
                  margin: "0 auto",
                }}
              >
                <div>Review Notes:</div>
                <div>Decision Notes:</div>
                <textarea
                  value={reviewNotes}
                  onChange={(e) => setReviewNotes(e.target.value)}
                ></textarea>
                <textarea
                  value={decisionNotes}
                  onChange={(e) => setDecisionNotes(e.target.value)}
                ></textarea>
                <div>Set Status:</div>
                <div>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {statusOptions.map((option, idx) => (
                      <option value={option} key={idx}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <input
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(180deg, rgba(96,147,97,1) 0%, rgba(36,90,45,1) 100%)",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    margin: "0 auto",
                    display: "block",
                  }}
                  type="button"
                  name="btnSetStatus"
                  value="Save"
                  onClick={() => saveApplication()}
                  id="btnSetStatus"
                  className="savebutton"
                />
              </div>

              <div>
                {stepsState.map((val, idx) => {
                  let stepId = `step-${idx}`;
                  return (
                    <div key={idx} id={stepId}>
                      <hr />
                      <span className="StepHeaderLabel">Step {idx + 1}</span>
                      <hr />
                      <div className="form">
                        {val.rows.map((row, rowIndex) => {
                          return (
                            <React.Fragment key={rowIndex}>
                              <Row
                                key={rowIndex}
                                rowIndex={rowIndex}
                                row={row}
                                stepIndex={idx}
                                disabled={true}
                              ></Row>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div>{loadingState}</div>
          )}
        </div>
      </>
    </>
  );
}
