import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPortal } from "../actions/selectionActions";

export function PortalSelector() {
  const [portals, setPortals] = useState([]);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const portal = useSelector((state) => state.selection.portal);

  useEffect(() => {
    if (auth.user.isSuperUser) {
      axios.get("/api/portals").then((res) => {
        setPortals(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (auth.user.isSuperUser && portals.length > 0)
      dispatch(setSelectedPortal(portals[0].portalId));
  }, [portals, auth.user.isSuperUser]);
  if (auth.user.isSuperUser && portals.length > 0) {
    return (
      <select
        value={portal}
        onChange={(e) => dispatch(setSelectedPortal(parseInt(e.target.value)))}
      >
        {portals.map((portal, idx) => (
          <option key={idx} value={portal.portalId}>
            {portal.displayName}
          </option>
        ))}
      </select>
    );
  } else return <></>;
}
